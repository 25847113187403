import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import config from '@/config'
import {getUrlParam} from "../libs/kit";

Vue.use(VueRouter)

const routes = [
  {
    path: '/tourist',
    name: 'tourist',
    component: ()=>import('@/views/tourist/tourist.vue'),
    meta: {
      title: '实名制登记预约平台'
    }
  },
  {
    path: '/tourist/success/:id/:scenicId',
    name: 'tourist_success',
    component: ()=>import('@/views/tourist/tourist_success.vue'),
    meta: {
      title: '实名制登记预约平台'
    }
  },
  {
    path: '/tourist/failed',
    name: 'failed',
    component: ()=>import('@/views/tourist/tourist_failed.vue'),
    meta: {
      title: '实名制登记预约平台'
    }
  },
  {
    path: '/process/traffic',
    name: 'traffic',
    component: ()=>import('@/views/process/traffic.vue'),
    meta: {
      title: '实名制登记预约平台'
    }
  },
  {
    path: '/process',
    name: 'process',
    component: ()=>import('@/views/process/index.vue'),
    meta: {
      title: '实名制登记预约平台'
    }
  },
  {
    path: '/ticket/:userId/:scenicId',
    name: 'ticket',
    component: ()=>import('@/views/ticket/index.vue'),
    meta: {
      title: '实名制登记预约平台'
    }
  },
  {
    path: '*',
    redirect: '/process'
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

 const local = "http://rna.hn-cloud.com/process";
 //const local = "http://192.168.1.54:8988/process";

// 不需要授权的白名单（可以是path也可以是name）
// 路由跳转前执行
router.beforeEach((to, from, next) => {
  let code = getUrlParam("code");
  let openId = store.state.openId;
  let token = store.state.token
  if (!token && !code) {
      let scenicId = getUrlParam("scenicId");
      if(scenicId){
          store.dispatch('saveScenicId', scenicId);
      }
     window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxaa3ed10ce5635686&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
    //  window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8eaa7f7ae1694738&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
    return
  }
  //地址中存在code切不存在token，请求后台获取openID
  if (!token && code) {
    if (!openId) {
      Vue.prototype.$post('/tourist/getOpenId', {code}).then(res => {
        store.dispatch('saveToken', res.object.token);
        store.dispatch('saveOpenId', res.object.openId);
        store.dispatch('saveUserId', res.object.userId);
        next()
      })
    }
  } else {
    next()
  }
})

//路由跳转后执行
router.afterEach(to => {
  //设置标题
  window.document.title = to.meta&&to.meta.title?to.meta.title:config.title
  //滚动至页面顶部
  window.scrollTo(0, 0)
})

export default router
