<template>
    <router-view/>
</template>


<script>
    import {getUrlParam} from "./libs/kit";

    export default {
        name: 'App',
        data() {
            return {};
        },
        created() {
            //获取景区id
            let scenicId = getUrlParam("scenicId");
            if (scenicId !=null && scenicId !== undefined) {
                this.$store.dispatch('saveScenicId', scenicId)
            }
        },
        methods: {
        }
    }
</script>

<style lang="scss">
    * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
    }

    body ,html{
        font-size: 12px;
        -webkit-font-smoothing: antialiased;
        margin: 0;
        padding: 0;
        background-color: #f7f8fa;
        min-height: 100vh;
        /*overflow: hidden;*/
    }
    .page-regist .van-radio{
        font-size: 4.2vw;
    }
    .title-box{
        margin: 0 auto ;
        padding:8vw 4vw 0;
        font-size: 6vw;
    }
</style>
