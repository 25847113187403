const isDev = process.env.NODE_ENV !== 'production'
export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: '',
  /**
   * @description token在Cookie中存储的天数，默认1天
   */
  cookieExpires: 1,
  /**
   * 请求头中的token参数名
   */
  tokenKey: "token",
  /**
   * @description api请求基础路径
   */
  baseUrl: isDev?'/api':'/api',
  /**
   * 文件服务器地址前缀，用于页面显示后台返回的图片时，以uploadPre+db.imageUrl来显示
   * 如图片地址：http://xxx.com/path/picture.png，其中uploadPre=http://xxx.com，其中db.imageUrl=/path/picture.png
   */
  uploadPre: isDev?'http://127.0.0.1:8888/rna/':'http://static.sxwlxx.cn/',
  /**
   * 微信网页授权路径
   */
  authorizationUrl:'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4066523a0444098c&redirect_uri=http%3A%2F%2F192.168.1.144&response_type=code&scope=snsapi_base&state=123#wechat_redirect'

}
