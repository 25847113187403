import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import config from '@/config'
import {AUDIT} from '@/libs/const'

const TOKEN_KEY = 'token'
const OPEN_KEY = 'open'
const USER_KEY = 'userId'
const SCENIC_KEY = 'scenic'

const setToCookie = (key,token) => {
    localStorage.setItem(key, token)
}

const getFromCookie = (key) => {
  let token = localStorage.getItem(key)
  if (key == OPEN_KEY){
  }
  if (token) return token
  else return false
}

const removeFromCookie = (key) => {
    localStorage.removeItem(key)
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getFromCookie(TOKEN_KEY),
    openId:getFromCookie(OPEN_KEY),
    userId:getFromCookie(USER_KEY),
    scenicId:getFromCookie(SCENIC_KEY),
    audit: AUDIT.PROCESS
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      if (token) {
        setToCookie(TOKEN_KEY,token)
      } else {
        removeFromCookie(TOKEN_KEY)
      }
    },
    setAudit(state, audit) {
      state.audit = audit
    },
    setScenicId(state, scenicId) {
      state.scenicId = scenicId
      if (scenicId) {
        setToCookie(SCENIC_KEY,scenicId)
      } else {
        removeFromCookie(SCENIC_KEY)
      }
    },
    setOpenId(state, openId) {
      state.openId = openId
      if (openId) {
        setToCookie(OPEN_KEY,openId)
      } else {
        removeFromCookie(OPEN_KEY)
      }
    },
    setUserId(state, userId) {
      state.userId = userId
      if (userId) {
        setToCookie(USER_KEY,userId)
      } else {
        removeFromCookie(USER_KEY)
      }
    }
  },
  getters: {
    isLogined: state => !!state.token,
    token: state => state.token,
    audit: state => state.audit
  },
  actions: {
    //设置登录信息token
    login({commit},token) {
      commit('setToken', token)
    },
    // 退出登录
    logout({commit}) {
      commit('setToken', null)
    },
    //保存景区id
    saveScenicId({commit},scenicId){
      commit('setScenicId',scenicId)
    },
    //保存openID
    saveOpenId({commit},openId){
      commit('setOpenId',openId)
    },
    //保存openID
    saveUserId({commit},userId){
      commit('setUserId',userId)
    },
    //保存token
    saveToken({commit},token) {
      commit('setToken', token)
    },
  }
})
