import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from '@/config'

Vue.config.productionTip = false

// 引入mock开发调试数据
if (process.env.NODE_ENV !== 'production'){
  require('@/mock')
}

// 引入自定义图标
import '@/assets/css/iconfont.css'

//图片懒加载指令
import {Lazyload} from 'vant'
Vue.use(Lazyload);

// 引入vant所有组件，也可切换为按需引入，只需要三步：
// 1.注释本段代码，2.解开babel.config.js中相关代码，3.各页面中组件自行引入
// import Vant from 'vant';
// import 'vant/lib/index.css';
// Vue.use(Vant);

// 将config配置文件添加至vue实例中，方便使用
Vue.prototype.$config = config

// 封装axio为常用的post、get、upload请求，添加至vue实例中，方便使用
import {post,get,upload} from '@/libs/request'
Vue.prototype.$post = post
Vue.prototype.$get = get
Vue.prototype.$upload = upload

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
