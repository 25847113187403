/**获取图片元信息*/
const getMimeType = (base64Str) => {
    let arr = base64Str.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    // return mime.replace("image/", "");
    return mime;
};
/**
 * 压缩Base64字符串形式的图片<br/>
 * 用法：<br/>
 * let base64String = '......';
 * compressBase64Image(base64String, 0.5).then(compressedBase64String => {
 *      console.log('压缩后的图片base64字符串：' + compressedBase64String);
 * });
 * @param {*} base64String base64字符串
 * @param {*} quality 压缩图片质量，取值范围为0到1
 */
export const compressBase64Image = (base64String, quality) => {
    let newImage = new Image(), imgWidth, imgHeight;

    let promise = new Promise(resolve => newImage.onload = resolve);
    newImage.src = base64String;
    return promise.then(() => {
        imgWidth = newImage.width;
        imgHeight = newImage.height;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        if (Math.max(imgWidth, imgHeight) > w) {
            if (imgWidth > imgHeight) {
                canvas.width = w;
                canvas.height = w * imgHeight / imgWidth;
            } else {
                canvas.height = w;
                canvas.width = w * imgWidth / imgHeight;
            }
        } else {
            canvas.width = imgWidth;
            canvas.height = imgHeight;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height);
        let compressedBase64String = canvas.toDataURL(getMimeType(base64String), quality);
        return compressedBase64String;
    });
}

export const getUrlParam = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg); //匹配目标参数
    if (r != null) return unescape(r[2]);
    return null; //返回参数值
}

//校验身份证
export const isCardNo =(card) =>{
    var pattern = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    return pattern.test(card);
}

//校验手机号
export const isPhoneNo =(phone) =>{
    var pattern = /^1[3-9]\d{9}$/;
    return pattern.test(phone);
}
