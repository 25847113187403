/**
 * 常量定义
 */
export const AUDIT = {
    PROCESS: 0, //处理中/审核中
    PASSED: 1, //已通过
    REJECT: 2 //已拒绝/未通过
}

//其他普通的常量
export const PUBLIC = {
    'yesornot': [
        {value:1, text:'是'},
        {value:0, text:'否'}
    ],
    'idtype': [
        {value:1, text:'身份证'},
        {value:0, text:'其他'}
    ],
    'relation': [
        {value:1, text:'父亲'},
        {value:2, text:'母亲'}
    ]
}
