import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import router from '../router'
import {Toast , Dialog} from 'vant'
import config from '@/config'

export const FLAGS = {
    SUCCESS: 0,
    FAILED: -1
}

export const CODES = {
    CODE_NO_ACTION: 'no_action',
    CODE_NO_LOGIN: 'no_login'
}

// 全局的请求队列，用于判断是否要显示加载动画
let requestQueue = {}
let isLoading = false
class HttpRequest {
    getInsideConfig () {
        return {
            // 设置超时时间
            timeout: 60000,
            baseURL: config.baseUrl,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'post': {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            }
        }
    }
    destroy (url) {
        delete requestQueue[url]
        if (!Object.keys(requestQueue).length && isLoading) {
            Toast.clear()
            isLoading = false
        }
    }
    interceptors (instance, url) {
        // 请求拦截
        instance.interceptors.request.use(cfg => {
            if (store.getters.token) {
                cfg.headers[config.tokenKey] = store.getters.token// 让每个请求携带自定义token 请根据实际情况自行修改
            }
            requestQueue[url] = true
            // 添加loading...
            setTimeout(() => {
                if (requestQueue[url] && !isLoading) {
                    isLoading = true
                    Toast.loading({
                        message: '加载中...',
                        //鼠标点击不关闭
                        forbidClick: true
                    });
                }
            }, 2000)
            return cfg
        }, error => {
            return Promise.reject(error)
        })
        // 响应拦截
        instance.interceptors.response.use(response => {
            this.destroy(url)
            const { data, status } = response
            // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
            if (status === 200) {
                if (data && data.code) {
                    if(data.code=='sys_token_disabled'){
                        store.state.token=null
                        store.state.openId=null
                        store.state.userId=null
                        localStorage.removeItem('token')
                        localStorage.removeItem('open')
                        localStorage.removeItem('userId')
                        console.log('store的token~~~~~~~~~~~~~`'+store.state.token);
                        Dialog.alert({
                            title: '提示',
                            message: '身份信息已失效，需重新授权'
                        }).then(()=>{
                            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxaa3ed10ce5635686&redirect_uri=' + encodeURIComponent('http://rna.hn-cloud.com/process') + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
                            return
                        })
                    }
                    switch (data.code) {
                    case CODES.CODE_NO_ACTION:
                        // 对应的接口
                        Dialog.alert({
                            title: '提示',
                            message: '接口不存在'
                        })
                        break
                    case CODES.CODE_NO_LOGIN:
                        if(!store.state.user.isLogined){
                            router.push({path:"/login"})
                            return
                        }
                        //否则表示正在使用过程中session失效导致登录状态失效，此时应该给用户以弹框提示
                        // 未登录或登录失效
                        Dialog.alert({
                            title: '提示',
                            message: '请重新登录'
                        }).then(()=>{
                            router.push({path:"/login"})
                        })
                        break
                    }
                    return
                }
                // 操作成功或错误代码不在统一范围内时，交由业务自行处理
                return Promise.resolve(data)
            } else {
                // 否则的话抛出错误
                return Promise.reject(response)
            }
        }, error => {
            this.destroy(url)
            // 断网 或者 请求超时 状态
            if (!error.response) {
                // 请求超时状态
                if (error.message.includes('timeout')) {
                    Dialog.alert({
                        title: '提示',
                        message: '请求超时，请检查网络是否连接正常'
                    })
                } else {
                    // 可以展示断网组件
                    Dialog.alert({
                        title: '提示',
                        message: '请求失败，请检查网络是否连接正常'
                    })
                }
                return
            }
            const { status } = error.response
            // 服务器返回不是 2 开头的情况，会进入这个回调
            // 可以根据后端返回的状态码进行不同的操作
            switch (status) {
            // 403: 没有权限
            case 403:
                // 弹出错误信息
                Dialog.alert({
                    title: '提示',
                    message: '您的操作没有权限'
                }).then(()=>{
                    window.location.reload()
                })
                break
                // 404请求不存在
            case 404:
                Dialog.alert({
                    title: '提示',
                    message: '您请求的信息不存在'
                })
                break
                // 其他错误，直接抛出错误提示
            default:
                Dialog.alert({
                    title: '提示',
                    message: error.message
                })
            }
            return Promise.reject(error)
        })
    }
    request (options) {
        const instance = axios.create()
        options = Object.assign(this.getInsideConfig(), options)
        this.interceptors(instance, options.url)
        return instance(options)
    }
}

export default post

export const post = (url, params) => {
    let axios = new HttpRequest()
    return axios.request({
        method: 'post',
        url,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(params)
    });
}

export const get = url => {
    let axios = new HttpRequest()
    return axios.request({
        method: 'get',
        url
    });
}

export const upload = (url, formData) => {
    let axios = new HttpRequest()
    return axios.request({
        method: 'post',
        url,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData
    })
  }

